import React from 'react'
import { withStyles } from '@material-ui/core'

const closeIconStyles = theme => ({
    root: {
      position: 'absolute',
      width: 32,
      height: 32,
      opacity: 0.3,
  
      '&:hover': {
        opacity: 1
      },
  
      '&:before, &:after': {
        position: 'absolute',
        left: 15,
        content: '""',
        height: 33,
        width: 4,
        backgroundColor: '#333'
      },
  
      '&:before': {
        transform: 'rotate(45deg)'
      },
  
      '&:after': {
        transform: 'rotate(-45deg)'
      }
    }
  })

  const CloseIcon = withStyles(closeIconStyles)(({ onClick, classes }) => {
    const handleClick = e => onClick && onClick(e)
  
    return <div onClick={handleClick} className={classes.root} />
  })

  export default CloseIcon