import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import theme from 'src/theme'
import { ThemeProvider, CssBaseline, makeStyles, Box } from '@material-ui/core'
import image from '../../static/template-bg.png'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
    bgImage: {
        position: 'relative',

        '&:before': {
            content: "''",
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '70% -50px',
            opacity: .5
        }
    }
}))

const Layout = ({ children }) => {
    const classes = useStyles()

    return (

        <ThemeProvider theme={theme}>
            <CssBaseline />

            <Box display="flex" flexDirection="column" className={classes.bgImage}>
                <Navbar />

                <main>
                    {children}
                </main>

                <Footer />
            </Box>
        </ThemeProvider>

    )
}

export default Layout
