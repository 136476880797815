import React from 'react'
import PinIcon from "../../../static/icons/pin.svg"
import LogoIcon from "../../../static/logo-md.svg"
import TwitterIcon from "../../../static/icons/Twitter.svg"
import LinkedinIcon from "../../../static/icons/Linkedin.svg"
import FacebookIcon from "../../../static/icons/facebook.svg"
import { Grid, Container, Box, Typography, Link, withStyles, useTheme, useMediaQuery } from '@material-ui/core'
import { scrollToSection } from 'src/utils'


const socials = [
  { iconSrc: LinkedinIcon, url: 'https://www.linkedin.com/company/promate-pl/', name: 'linkedin' },
  { iconSrc: FacebookIcon, url: 'https://www.facebook.com/promate.software/', name: 'facebook' },
]

const styles = theme => ({
  [theme.breakpoints.only('sm')]: {
    logoSection: {
      order: 1
    },
    addressSection: {
      order: 3
    },
    additionalLinksSection: {
      order: 4
    },
    linksSection: {
      order: 2
    },
  },
  [theme.breakpoints.only('xs')]: {
    logoSection: {
      order: 4
    }
  },

  centerOnMobile: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
  }
})

export default withStyles(styles)(({ classes }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box bgcolor="white" py={6} mt="auto" position="relative">
      <Container component="footer">
        <Grid container spacing={3} alignItems="flex-start" className={classes.centerOnMobile}>
          <Box component={Grid} item xs={12} sm={6} md={3} color="divider" className={classes.logoSection}>
            <img src={LogoIcon} alt="" />

            <Box my={2}>
              <Grid container spacing={2} justify={isMobile ? "center" : "flex-start"}>
                {socials.map(({ iconSrc, url, name }) => <Grid key={name} item>
                  <Link href={url}>
                    <img src={iconSrc} alt={name} />
                  </Link>
                </Grid>)}
              </Grid>
            </Box>

            <Typography variant="body2" color="textSecondary">Copyright © 2020 Promate</Typography>
          </Box>


          <Grid item xs={12} sm={6} md={3} className={classes.addressSection}>
            <Box mb={2}>
              <img src={PinIcon} alt="" />
            </Box>

            <Typography variant="h3"><Box color="divider">Warsaw</Box></Typography>
            <Typography variant="h3"><Box color="divider">Cracow</Box></Typography>

            <Box component="address" variant="body1" fontStyle="normal" color="text.secondary" mt={2}>
              <Typography variant="body1">
                <b>Promate Sp. z o.o.</b>
              </Typography>
              <Typography variant="body1">ul. Mazowiecka 11/49</Typography>
              <Typography variant="body1">00-052 Warszawa</Typography>
              <Typography variant="body1">NIP: 848-18-29-465</Typography>
            </Box>
          </Grid>


          <Grid item xs={12} sm={6} md={3} container spacing={1} className={classes.linksSection}>
            {[
                ['services', 'Services'],
                ['about', 'About Us'],
                ['projects', 'Projects'],
              ].map(([name, label]) => <Grid key={name} item xs={12}>
                <Link
                variant="body1"                
                onClick={scrollToSection(name)}
                href={`#${name}`}
              >
                <Box component="span" fontWeight="fontWeightBold">{label}</Box>                
              </Link>
              </Grid>
              )}         
          </Grid>

          <Grid item xs={12} sm={6} md={3} container spacing={1} className={classes.linksSection}>
            {[
                ['faq', 'FAQs'],
                ['clients', 'Clients'],
                ['contact', 'Contact'],
              ].map(([name, label]) => <Grid key={name} item xs={12}>
                <Link
                variant="body1"                
                onClick={scrollToSection(name)}
                href={`#${name}`}
              >
                <Box component="span" fontWeight="fontWeightBold">{label}</Box>                
              </Link>
              </Grid>
              )}         
          </Grid>

          {/* <Grid item xs={12} sm={6} md={3} container spacing={1} className={classes.additionalLinksSection}>
            {['Terms & Conditions', 'Privacy Policy', 'Cookie Policy'].map(x => <Grid key={x} item xs={12}>
              <Link>{x}</Link>
            </Grid>)}
          </Grid> */}
        </Grid>
      </Container>
    </Box>
  )
})