import { createMuiTheme } from "@material-ui/core/styles"

const BRAND_COLOR = '#6F2C91'
const theme = createMuiTheme({
	palette: {
		primary: {
			main: BRAND_COLOR,
		},
		background: {
			default: '#fafafa'
		},
		divider: '#a4a4a4' // '#dcdcdc'
	},
	typography: {
		fontFamily: '"Barlow", sans-serif',
		fontSize: 16,
		h2: {
			fontSize: '2.125rem',
			fontWeight: 600,
			lineHeight: '1.235'
		},
		h3: {
			fontSize: '1.5rem',
			fontWeight: 600,
			lineHeight: '1.334'
		}
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				main: {
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100vh'
				},
			},
		},
		MuiButton: {
			root: {
				fontFamily: 'Alegreya Sans',
				fontWeight: 'bold',
				fontSize: 14,
				lineHeight: `17px`,
			},
			containedPrimary: {
				boxShadow: 'none',
				border: `2px solid ${BRAND_COLOR}`,
				padding: `10px 25px`,
				borderRadius: 50,
				minWidth: 160,
				color: '#fff',

				'&:hover': {
					boxShadow: 'none',
					backgroundColor: `#fff`,
					color: BRAND_COLOR,
				}
			}
		},
		MuiLink: {
			root: {
				cursor: 'pointer',
				color: 'rgba(0, 0, 0, 0.87)', // theme.palette.text.primary
				fontSize: '1.2rem',

				'&:hover': {
					color: BRAND_COLOR,
				}
			}
		}
	},
	props: {
		MuiLink: {
			underline: 'none'
		}
	}
})

export default theme