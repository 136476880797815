import React from 'react'
import cx from 'classnames'
import { Button } from '@material-ui/core'

const CompanyButton = ({ className, children, onClick, type,  ...props }) => {

  return (
    <Button type={type} className={cx(className)} variant="contained" color="primary" onClick={onClick} {...props}>
      {children}
    </Button>
  )
}

export default CompanyButton
