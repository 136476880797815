import { NAVBAR_HEIGHT_SHRINKED } from "./components/Navbar/Navbar"
import ReactDOM from 'react-dom'
import { navigate } from '@reach/router'

const noop = () => { }

export const scrollToSection = (section, { beforeFn, afterFn } = { beforeFn: noop, afterFn: noop }) => e => {
    if (window.location.pathname !== '/') {
        navigate(`/#${section}`)
    } else {
        e.preventDefault()
        beforeFn()

        const offsetTop = -NAVBAR_HEIGHT_SHRINKED

        const getTarget = () => ReactDOM.findDOMNode(document.getElementById(section))
        const sectionTop = getTarget().getBoundingClientRect().y + window.scrollY

        window.scrollTo({
            top: sectionTop + offsetTop,
            behavior: 'smooth'
        })

        window.history.pushState(null, null, `#${section}`)
    }
}
