import React from 'react'
import CompanyButton from '../CompanyButton/CompanyButton'
import LogoText from "../../../static/Logo_Text.svg"
import LogoSignet from "../../../static/Logo_Signet.svg"
import cx from 'clsx'
import { Container, Hidden, IconButton, withStyles, Grid, Link, Drawer, Box } from '@material-ui/core'
import CloseIcon from '../CloseIcon'
import { Link as RouterLink, navigate } from "@reach/router"
import { scrollToSection } from 'src/utils'

export const NAVBAR_HEIGHT_SHRINKED = 70

class Navbar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dense: false,
      menuOpen: false
    }
  }

  handleScroll = e => {
    const { dense } = this.state
    const scrollTop = window.pageYOffset

    if (scrollTop > 40 && !dense) {
      this.setState({ dense: true })
    } else if (scrollTop === 0 && dense) {
      this.setState({ dense: false })
    }
  }

  handleLogoClick = e => {
    e.preventDefault()

    if (window.location.pathname === '/') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    } else {
      window.history.pushState(null, null, ``)
      navigate('/')
    }
  }

  scrollToSection = section => e => {
    const beforeFn = () => { this.state.menuOpen && this.closeMenu() }
    scrollToSection(section, { beforeFn })(e)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  openMenu = () => this.setState({ menuOpen: true })

  closeMenu = () => this.setState({ menuOpen: false })

  render() {
    const { classes } = this.props
    const { dense, menuOpen } = this.state

    return (<>
      <div className={cx(classes.wrapper, {
        [classes.wrapperDense]: dense
      })}>
        <Container component="nav" className={cx("Navbar Navbar-expand-lg Navbar-light bg-light", classes.root, {
          [classes.rootDense]: dense
        })}>
          <a className="navbar-brand" href="/" onClick={this.handleLogoClick}>
            <div className={classes.logoWrapper}>
              <img src={LogoSignet} alt="" className={cx(classes.logoSignet)} />
              <img src={LogoText} alt="" className={cx(classes.logoText)} />
            </div>
          </a>

          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <Hidden xsDown>
              <ul className={cx(classes.navbarNav, "mr-auto")}>
                <li className="nav-item active">
                  <Link className="nav-link" href="#services" onClick={this.scrollToSection('services')}>Services</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" href="#about" onClick={this.scrollToSection('about')}>About Us</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" href="#projects" onClick={this.scrollToSection('projects')}>Projects</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" href="#faq" onClick={this.scrollToSection('faq')}>FAQs</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" href="#clients" onClick={this.scrollToSection('clients')}>Clients</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" href="#contact" onClick={this.scrollToSection('contact')}>Contact</Link>
                </li>
                <Hidden smDown>
                  <li className="nav-item">
                    <CompanyButton component={RouterLink} to="/contact" className="contact-btn">Send Inquiry</CompanyButton>
                  </li>
                </Hidden>
              </ul>
            </Hidden>

            <Hidden smUp>
              <IconButton color="primary" onClick={this.openMenu}>
                <MenuIcon />
              </IconButton>

              <Drawer anchor="right" open={menuOpen} onClose={() => this.closeMenu()}>
                <Box minHeight="100vh" display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" position="relative">
                  <Box mt={0} p={3} mb="4em" position="absolute" top={0} display="flex" alignItems="center" justifyContent="flex-end" width="100%">

                    <Box ml="auto" mr="0">
                      <IconButton color="primary" onClick={() => this.closeMenu()}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box p={6}>
                    <Grid container spacing={6} justify="center" align="center">
                      {
                        [
                          ['services', 'Services'],
                          ['about', 'About Us'],
                          ['projects', 'Projects'],
                          ['faq', 'FAQs'],
                          ['clients', 'Clients']
                        ].map(([name, label]) => <Grid key={name} item xs={12}>
                          <Link
                            onClick={this.scrollToSection(name)}
                            href={`#${name}`}
                            className="MuiTypography-root MuiLink-root MuiLink-underlineNone MuiTypography-colorPrimary"
                          >
                            <b>{label}</b>
                          </Link>
                        </Grid>)
                      }
                      <Grid item xs={12}>
                        <CompanyButton component={RouterLink} to="/contact">Send Inquiry</CompanyButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Drawer>
            </Hidden>
          </div>
        </Container>
      </div>
      <div className={cx(classes.test, {
        [classes.testDense]: dense
      })} />
    </>
    )
  }
}




const menuIconStyles = theme => ({
  bar: {
    width: theme.spacing(4),
    height: theme.spacing(.5),
    backgroundColor: theme.palette.primary.main,
    margin: '6px 0'
  }
})
const MenuIcon = withStyles(menuIconStyles)(({ onClick, classes }) => {
  const handleClick = e => onClick && onClick(e)

  return <div onClick={handleClick}>
    {[0, 1, 2].map(x => <div
      key={x}
      className={classes.bar}
    />)}
  </div>
})


const styles = theme => ({
  wrapper: {
    position: 'fixed',
    top: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    zIndex: '100',
    backgroundColor: 'transparent',
    transition: 'background-color .2s, box-shadow .4s',
    transitionDelay: '0, 0',
  },

  wrapperDense: {
    transitionDelay: '.1s, .1s',
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 3px 5px -1px rgba(0, 0, 0, 0.02), 0px 5px 8px 0px rgba(0, 0, 0, 0.005),
      0px 1px 14px 0px rgba(0, 0, 0, 0.02)`
  },

  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: 1146,
    height: 151,
    transition: 'height 0.8s, all .4s',
  },

  rootDense: {
    height: 73,

    "& $logoSignet": {
      width: 0
    }
  },

  test: {
    height: 151,
    transition: 'height .8s',
  },

  testDense: {
    height: 151
  },

  logoText: {
    marginTop: 4,
    maxWidth: '100%'
  },

  logoSignet: {
    width: '100%',
    transition: 'width 0.2s',
  },

  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 146
  },

  logoWrapperSmall: {
    width: 88
  },

  navbarNav: {
    display: 'flex',
    listStyle: 'none',
    alignItems: 'center',
    flexWrap: 'wrap',

    '& a:not(.contact-btn)': {
      textDecoration: 'none',
      fontWeight: 'bold',
      padding: '0 15px',
      fontSize: 18,
    },

    '& .contact-btn': {
      marginLeft: 15
    }
  }
})


export default withStyles(styles)(Navbar)